<template>
  <div>
    <h3 class="text-secondary content-heading">Ustawienia</h3>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Product</h5>
            <p class="card-text">allows you to manage your products</p>
            <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/settings/product"
            >
              <a :href="href" class="btn btn-primary" @click="navigate">
                <span>Go</span>
              </a>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Insurer</h5>
            <p class="card-text">allows you to manage insurers</p>
            <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/settings/insurer"
            >
              <a :href="href" class="btn btn-primary" @click="navigate">
                <span>Go</span>
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">User</h5>
            <p class="card-text">allows you to manage your users</p>
            <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              to="/settings/user"
            >
              <a :href="href" class="btn btn-primary" @click="navigate">
                <span>Go</span>
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsView"
};
</script>
